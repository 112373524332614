import React, { useState } from 'react'

function Question5() {
    const [active, setActive] = useState(false)

    return (
        <div className={`${active && 'active'}`}>
            <h3 onClick={() => setActive(!active)}><i className={`fa-solid fa-caret-${active ? 'down' : "right"}`} />What would I need for each session?</h3>
            {active && <p>A Laptop or Smartphone. Also try to be in a quiet environment during sessions.</p>}
        </div>
    )
}

export default Question5