import React, { useState } from 'react'

function Question3() {
    const [active, setActive] = useState(false)

    return (
        <div className={`${active && 'active'}`}>
            <h3 onClick={() => setActive(!active)}><i className={`fa-solid fa-caret-${active ? 'down' : "right"}`} />How many days in a week would the classes hold?</h3>
            {active && <p>We have a maximum of 3 sessions holding every week</p>}
        </div>
    )
}

export default Question3