import React, { useState } from 'react'
import Modal from 'react-modal'
Modal.setAppElement('#root')

function AdejumokeAyodele() {
    const [modal, setModal] = useState(false)
    return (
        <div className="card">
            <div className="top">
                <img src="img/unsplash_A3MleA0jtoE.png" className="img-fluid" alt="Adejumoke Ayodele" />
            </div>
            <div className="bottom">
                <h4>Adejumoke Ayodele</h4>
                <h5 onClick={() => setModal(true)}>Click to read more</h5>
            </div>
            <Modal isOpen={modal} onRequestClose={() => setModal(false)} className="facilitator-modal">
                <i class="fa-solid fa-xmark" onClick={() => setModal(false)}></i>
                <div className="row align-items-center mb-5">
                    <div className="col-5">
                        <img src="/img/unsplash_A3MleA0jtoE.png" className='img-fluid' alt="Adejumoke Ayodele" />
                    </div>
                    <div className="col-7">
                        <h1 className='mb-0'>Adejumoke Ayodele</h1>
                    </div>
                </div>
                <h3>Background</h3>
                <p className='mb-0'>
                    Adejumoke Ayodele, also known as the Eclectic Voice, is a Media consultant and Voice over Talent. <br /><br />
                    She has worked in the media industry for over 10 years gaining experiences in Media Presentation, Programme Production, Copy writing, Script development, Blogging, Voice overs, Vocal Direction and Production. <br /><br />
                    She is intrigued with storytelling and how these stories transpires into positive impact in the society. <br /><br />
                    Some of her clients include: Iberian Media, Redtown Digital, DDB. She also has her voice on various commercials, audiobooks, IVR and documentaries, such as YouTube Music Premium, Kellogg’s, Polaris Bank, Alliance insurance and so on. <br /><br />
                    She currently is the Podcast host of the top ten relationships podcast in Nigeria, Date Sex and love by Jamit.
                </p>
            </Modal>
        </div>
    )
}

export default AdejumokeAyodele