import React from 'react'
import Footer from '../components/footer'
import Nav from '../components/nav'

function WhoWeAre() {
    return (
        <div className="container-fluid who-we-are">
            {/* navigation */}
            <Nav />
            <header>
                <h1>Who we are</h1>
            </header>
            <div className="content container">
                <p>Kickstart Into Vos (Voiceovers) is committed to developing and creating sound and professional talents
                    who are excellent
                    in every sphere of their voiceover profession. <br /><br />
                    Since 2020, we have been on a journey to impact and add value to budding, aspiring and Practicing
                    talents across the
                    globe through webinars, workshops, impactful contents and our 3 months virtual course called Journey to
                    Voiceovers. <br /><br />
                    Our range of experienced, friendly and supportive facilitators from across the world will lead and guide
                    you on your
                    path to voiceover greatness. <br /><br />
                    Come along this journey with us.
                </p>
                <div className="row justify-content-sm-center">
                    <div className="col-lg-4 col-md-4 col-sm-6 d-flex justify-content-center mb-lg-0 mb-md-0 mb-sm-3 mb-3">
                        <div className="card">
                            <img src="img/vision.png" className="img-fluid" alt="vision" />
                            <h3>our vision</h3>
                            <div className="d-flex">
                                <i className="fa-solid fa-circle" />
                                <p>To be friend every voice actor confides in</p>
                            </div>
                            <div className="d-flex">
                                <i className="fa-solid fa-circle" />
                                <p>To be a positive influence to voice actors across the world</p>
                            </div>
                            <div className="d-flex">
                                <i className="fa-solid fa-circle" />
                                <p>To be a foremost global voiceover educational institute who will be producers of sound
                                    and professional talents who are
                                    on top of their game.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 d-flex justify-content-center mb-lg-0 mb-md-0 mb-sm-3 mb-3">
                        <div className="card">
                            <img src="img/nission.png" className="img-fluid" alt="mission" />
                            <h3>our MISSION</h3>
                            <div className="d-flex">
                                <i className="fa-solid fa-circle" />
                                <p>Make Voice acting a delight for everyone</p>
                            </div>
                            <div className="d-flex">
                                <i className="fa-solid fa-circle" />
                                <p>Connect Voice actors from various continents of the world.</p>
                            </div>
                            <div className="d-flex">
                                <i className="fa-solid fa-circle" />
                                <p>To be the Voice Actor’s Information guide and resource.</p>
                            </div>
                            <div className="d-flex">
                                <i className="fa-solid fa-circle" />
                                <p>To provide World Class education of Professional Standard to voiceover talents..</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 d-flex justify-content-center">
                        <div className="card">
                            <img src="img/values.png" className="img-fluid" alt="values" />
                            <h3>CORE VALUES</h3>
                            <div className="d-flex">
                                <i className="fa-solid fa-circle" />
                                <p>Support</p>
                            </div>
                            <div className="d-flex">
                                <i className="fa-solid fa-circle" />
                                <p>Tenacity</p>
                            </div>
                            <div className="d-flex">
                                <i className="fa-solid fa-circle" />
                                <p>Excellence</p>
                            </div>
                            <div className="d-flex">
                                <i className="fa-solid fa-circle" />
                                <p>Transparency</p>
                            </div>
                            <div className="d-flex">
                                <i className="fa-solid fa-circle" />
                                <p>Friendliness</p>
                            </div>
                            <div className="d-flex">
                                <i className="fa-solid fa-circle" />
                                <p>Courage</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default WhoWeAre