import React, { useState } from 'react'

function Question4() {
    const [active, setActive] = useState(false)

    return (
        <div className={`${active && 'active'}`}>
            <h3 onClick={() => setActive(!active)}><i className={`fa-solid fa-caret-${active ? 'down' : "right"}`} />I don’t have time to attend sessions every week?</h3>
            {active && <p>Attending live sessions are important, however if for any reason you miss a live class you have access to a replay to be sent to your email, that way you get the Live session experience. Furthermore, you are entitled to ask questions and seek clarifications when necessary. However, endeavor not to miss many live sessions.</p>}
        </div>
    )
}

export default Question4