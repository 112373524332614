import React, { useState } from 'react'

function Question6() {
    const [active, setActive] = useState(false)

    return (
        <div className={`${active && 'active'}`}>
            <h3 onClick={() => setActive(!active)}><i className={`fa-solid fa-caret-${active ? 'down' : "right"}`} />Do I get a certificate?</h3>
            {active && <p>Yes, a certificate of participation would be issued after completion of all tasks and the class project.</p>}
        </div>
    )
}

export default Question6