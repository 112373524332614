import React, { useState } from 'react'

function Question2() {
    const [active, setActive] = useState(false)
    return (
        <div className={`${active && 'active'}`}>
            <h3 onClick={() => setActive(!active)}><i className={`fa-solid fa-caret-${active ? 'down' : "right"}`} />I have participated in many workshops and courses.
                Why should I sign up for this?</h3>
            {active && <p>Asides from the fact that this course is very affordable and is all encompassing. Many of our Facilitators from various continents of the world are very supportive and encouraging are bringing in diversity, uniqueness and their wealth of Voiceover experience. Your learning experience doesn’t stop after 3
                Months, we have a growing Alumni community where you get to engage in Weekly practice sessions, Monthly Hangouts and so much more.</p>}
        </div>
    )
}

export default Question2 