import React, { useState } from 'react'
import Modal from 'react-modal'
Modal.setAppElement('#root')

function JerryFischer() {
    const [modal, setModal] = useState(false)
    return (
        <div className="card">
            <div className="top">
                <img src="/img/Jerry Fischer.png" className="img-fluid" alt="Jennifer Martin" />
            </div>
            <div className="bottom">
                <h4>Jerry Fischer</h4>
                <h5 onClick={() => setModal(true)}>Click to read more</h5>
            </div>
            <Modal isOpen={modal} onRequestClose={() => setModal(false)} className="facilitator-modal">
                <i class="fa-solid fa-xmark" onClick={() => setModal(false)}></i>
                <div className="row align-items-center mb-5">
                    <div className="col-5">
                        <img src="/img/Jerry Fischer.png" className='img-fluid' alt="Jennifer Martin" />
                    </div>
                    <div className="col-7">
                        <h1 className='mb-0'>Jerry Fischer</h1>
                    </div>
                </div>
                <h3>Background</h3>
                <p className='mb-0'>
                    With a M.F.A. in Acting from UCLA, Jerry has enjoyed a variety of experiences in the theatrical world.  He was "bitten by the bug" in 2nd grade when he portrayed Peter Pan. Since then, he has acted and performed in Texas, Oklahoma, Arizona, Tennessee, Connecticut, New York, California, and Australia. He has been an adjunct professor of acting, directing, and voice and movement at Biola University in California and is the founder/director of a touring company called "His Majesty's Players" that travelled throughout Australia for six years. Currently, he is a member of a Comedy Improv Group (Fake News Show) in Los Angeles. <br /><br />
                    Regarding voiceovers, Jerry is diversified in the animation and commercial arenas. Having studied with notable voiceover coaches such as Ginny Tyler (the original voice for “Minnie Mouse”) and Joanie Gerber (who created voices for The Smurfs and DuckTales), he continues to hone his skills by attending ZOOM voiceover classes with SAG-AFTRA. One of his audiobooks, Fairview Felines, is on Amazon.com and exemplifies the multi-character voices that he can portray. Recently, he dubbed 20 different voices for a German documentary about a 1988 hostage crisis and is now being considered for several roles in the upcoming project True Wonders of the Bible.
                </p>
            </Modal>
        </div>
    )
}

export default JerryFischer