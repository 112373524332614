import ScrollToTop from "./components/scrollToTop";
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./screens/Home";
import WhoWeAre from "./screens/WhoWeAre";
import Program from "./screens/Program";
import Facilitators from "./screens/Facilitators";
import Courses from "./screens/Courses";
import './App.css';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/who-we-are" element={<WhoWeAre />} />
        <Route exact path="/programs" element={<Program />} />
        <Route exact path="/facilitators" element={<Facilitators />} />
        <Route exact path="/courses" element={<Courses />} />
      </Routes>
    </Router>
  );
}

export default App;
