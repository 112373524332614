import React, { useState } from 'react'

function Question1() {
    const [active, setActive] = useState(false)
    return (
        <div className={`${active && 'active'}`}>
            <h3 onClick={() => setActive(!active)}><i className={`fa-solid fa-caret-${active ? 'down' : "right"}`} />What is the Journey to Voiceovers course about?</h3>
            {active && <p>
                Journey to Voiceovers is Kickstart into Vos pioneer course focused at helping the budding, aspiring as well as the practicing voice actor strengthen their Vocal abilities and learn more about the Voiceover Business on their path to Voiceover Greatness. <br /><br />

                The Virtual Course holds for 3 Months with facilitators from various continents of the world. Practical tasks, exercises and webinars are scheduled and focused on in this course. Journey to Voiceovers include a certificate of participation after the completion of tasks and exercises.
            </p>}
        </div>
    )
}

export default Question1 