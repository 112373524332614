import React, { useState } from 'react'
import Modal from 'react-modal'
Modal.setAppElement('#root')

function DejiAbimbola() {
    const [modal, setModal] = useState(false)
    return (
        <div className="card">
            <div className="top">
                <img src="/img/h2.png" className="img-fluid" alt="Deji Abimbola" />
            </div>
            <div className="bottom">
                <h4>Deji Abimbola</h4>
                <h5 onClick={() => setModal(true)}>Click to read more</h5>
            </div>
            <Modal isOpen={modal} onRequestClose={() => setModal(false)} className="facilitator-modal">
                <i class="fa-solid fa-xmark" onClick={() => setModal(false)}></i>
                <div className="row align-items-center mb-5">
                    <div className="col-5">
                        <img src="/img/h2.png" className='img-fluid' alt="Deji Abimbola" />
                    </div>
                    <div className="col-7">
                        <h1 className='mb-0'>Deji Abimbola</h1>
                    </div>
                </div>
                <h3>Background</h3>
                <p className='mb-0'>
                    Ayodeji Abimbola, business development expert and Team Lead, Fresh Streams. <br /><br />
                    Ayodeji consults for multinationals and small businesses that need to set up market research and business development initiatives for expansion and growth. <br /><br />
                    He's also an alumnus of the Lagos Business School (Pan Atlantic University).
                </p>
            </Modal>
        </div>
    )
}

export default DejiAbimbola