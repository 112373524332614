import React, { useState } from 'react'
import Modal from 'react-modal'
Modal.setAppElement('#root')

function DayoButtons() {
    const [modal, setModal] = useState(false)
    return (
        <div className="card">
            <div className="top">
                <img src="/img/h4.png" className="img-fluid" alt="Dayo Buttons" />
            </div>
            <div className="bottom">
                <h4>Dayo Buttons</h4>
                <h5 onClick={() => setModal(true)}>Click to read more</h5>
            </div>
            <Modal isOpen={modal} onRequestClose={() => setModal(false)} className="facilitator-modal">
                <i class="fa-solid fa-xmark" onClick={() => setModal(false)}></i>
                <div className="row align-items-center mb-5">
                    <div className="col-5">
                        <img src="/img/h4.png" className='img-fluid' alt="Dayo Buttons" />
                    </div>
                    <div className="col-7">
                        <h1 className='mb-0'>Dayo Buttons</h1>
                    </div>
                </div>
                <h3>Background</h3>
                <p className='mb-0'>
                    Dayo Buttons is the founder of Plugged-In Village (Media outfit) <br /><br />
                    When you talk God, Voice overs, Radio and Food, you talk Dayo Buttons. <br /><br />
                    He graduated from the Nigerian institute of Journalism and has worked with and on different media projects, starting his radio career 2010 at unilag fm hosting Nature's Cardinals and Cubicles, then production assistant on Rubbin' Minds on Channels TV, currently working with a gospel online radio (Liveway Radio).
                </p>
            </Modal>
        </div>
    )
}

export default DayoButtons