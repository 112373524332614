import React from 'react'
import { Link, useNavigate } from "react-router-dom";

function Nav() {
    // history 
    const navigate = useNavigate()

    return (
        <div className="nav-container">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <Link to="/" className="navbar-brand"><img src="img/logo.png" className="logo" /></Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span />
                            <span />
                            <span />
                        </button>
                        <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className={`nav-item ${window.location.pathname.includes("/who-we-are") && 'active'}`}><Link to='/who-we-are' className="nav-link">Who we are</Link></li>
                                <li className={`nav-item ${window.location.pathname.includes("/programs") && 'active'}`}><Link to='/programs' className="nav-link">Our Programs</Link></li>
                                <li className={`nav-item ${window.location.pathname.includes("/facilitators") && 'active'}`}><Link to='/facilitators' className="nav-link">Facilitators</Link></li>
                            </ul>
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <button id="contact-us" onClick={() => navigate("/courses")}>View Course</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Nav 