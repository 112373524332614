import React from 'react'
import Footer from '../components/footer'
import Nav from '../components/nav'
import AdejumokeAyodele from '../components/facilitators/AdejumokeAyodele'
import BlessingKure from '../components/facilitators/BlessingKure'
import HussainAmiri from '../components/facilitators/HussainAmiri'
import DejiAbimbola from '../components/facilitators/DejiAbimbola'
import EmilyCass from '../components/facilitators/EmilyCass'
import DayoButtons from '../components/facilitators/DayoButtons'
import KhumiHamese from '../components/facilitators/KhumiHamese'
import MarsLipowski from '../components/facilitators/MarsLipowski'
import JerryFischer from '../components/facilitators/JerryFischer'
import OnyinyechiEkumankama from '../components/facilitators/OnyinyechiEkumankama'

function Facilitators() {
    return (
        <div className="container-fluid facilitators">
            {/* navigation */}
            <Nav />
            <header>
                <h1>Our Facilitators</h1>
            </header>
            <div className="facilitator-list container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6 d-flex justify-content-center">
                        <AdejumokeAyodele />
                    </div>
                    <div className="col-lg-4 col-sm-6 d-flex justify-content-center">
                        <BlessingKure />
                    </div>
                    <div className="col-lg-4 col-sm-6 d-flex justify-content-center">
                        <HussainAmiri />
                    </div>
                    <div className="col-lg-4 col-sm-6 d-flex justify-content-center">
                        <DejiAbimbola />
                    </div>
                    <div className="col-lg-4 col-sm-6 d-flex justify-content-center">
                        <EmilyCass />
                    </div>
                    <div className="col-lg-4 col-sm-6 d-flex justify-content-center">
                        <DayoButtons />
                    </div>
                    <div className="col-lg-4 col-sm-6 d-flex justify-content-center">
                        <KhumiHamese />
                    </div>
                    <div className="col-lg-4 col-sm-6 d-flex justify-content-center">
                        <MarsLipowski />
                    </div>
                    <div className="col-lg-4 col-sm-6 d-flex justify-content-center">
                        <OnyinyechiEkumankama />
                    </div>
                    <div className="col-lg-12 col-sm-6 d-flex justify-content-center">
                        <JerryFischer />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Facilitators