import React from 'react'
import Modal from 'react-modal'
Modal.setAppElement('#root')

function TransferModal({ transferModal, handleTransferModal, handleEnrollModal }) {
    return (
        < Modal isOpen={transferModal} onRequestClose={() => handleTransferModal(false)} id="transfer-modal" >
            <span id='back' onClick={() => {
                handleEnrollModal(true)
                handleTransferModal(false)
            }}><i class="fa-solid fa-angle-left"></i>Back</span>
            <i class="fa-solid fa-xmark" onClick={() => handleTransferModal(false)}></i>
            <div className="row mt-2">
                <div className="col-lg-4 col-md-4">
                    <h3>Kindly follow these instructions!!!</h3>
                    <p>1. Kindly use <span>Student’s Name + Workshop</span> as narration E.g Adams James, workshop</p>
                    <p>2. Once a transfer has been made successfully, Kindlly forward Transfer Receipt to <span>enroll@kickstartintovos.com</span></p>
                    <p>3. If you have a coupon code, please ensure to include it in your payment narration before making a transfer</p>
                </div>
                <div className="col-lg-8 col-md-8">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <h4>USD Accout Details </h4>
                            <p>Account Name: <span>Fresh Streams Works Ltd</span>. <br />
                                Account Number: <span>0039147520</span> <br />
                                Bank Name: <span>Stanbic IBTC</span> <br />
                                Bank Ref: <span>MC Workshop</span></p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <h4>Naira Accout Details</h4>
                            <p>Account Name: <span>Fresh Streams Works Ltd</span>. <br />
                                Account Number: <span>0026675421</span> <br />
                                Bank Name: <span>Stanbic IBTC Bank</span> <br />
                                Ref: MC Workshop</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <p className='mt-lg-5'>
                                INWARD REMITTANCE FOR USD PAYMENTS To: <span>Stanbic IBTC Bank PLC</span> <br />
                                Swift code: <span>SBICNGLX</span> <br />
                                For final credit to: <span>BENEFICIARY ACCOUNT NAME & NUMBER (In Stanbic)</span> <br />
                                Correspondent bank: <span>Citibank N.A.111 Wall Street</span> <br />
                                Swift code: <span>CITIUS33</span> <br />
                                <span>ABA 021000089</span> <br />
                                <span>New York</span> <br />
                                Correspondent bank account number: <span>36127476</span>
                            </p>
                        </div>
                    </div>
                </div>


                {/* <div className="col-lg-4 col-md-6 col-sm-6">
                    <h3>Kindly follow these instructions!!!</h3>
                    <p>1. Kindly use <span>Student’s Name + Workshop</span> as narration E.g Adams James, workshop</p>
                    <p>2. Once a transfer has been made successfully, Kindlly forward Transfer Receipt to <span>enroll@kickstartintovos.com</span></p>
                    <p>3. If you have a coupon code, please ensure to include it in your payment narration before making a transfer</p>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <h4>USD Accout Details </h4>
                    <p>Account Name: <span>Fresh Streams Works Ltd</span>. <br />
                        Account Number: <span>0039147520</span> <br />
                        Bank Name: <span>Stanbic IBTC</span> <br />
                        Bank Ref: <span>MC Workshop</span></p>
                </div>
                <div className="col-lg-4 col-md-5 col-sm-6 mt-lg-0 mt-md-4 mt-sm-3">
                    <h4>Naira Accout Details</h4>
                    <p>Account Name: <span>Fresh Streams Works Ltd</span>. <br />
                        Account Number: <span>0026675421</span> <br />
                        Bank Name: <span>Stanbic IBTC Bank</span> <br />
                        Ref: MC Workshop</p>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-6 mt-lg-0 mt-md-4 mt-sm-3">
                    <p className='mt-lg-5'>
                        INWARD REMITTANCE FOR USD PAYMENTS To: <span>Stanbic IBTC Bank PLC</span> <br />
                        Swift code: <span>SBICNGLX</span> <br />
                        For final credit to: <span>BENEFICIARY ACCOUNT NAME & NUMBER (In Stanbic)</span> <br />
                        Correspondent bank: <span>Citibank N.A.111 Wall Street</span> <br />
                        Swift code: <span>CITIUS33</span> <br />
                        <span>ABA 021000089</span> <br />
                        <span>New York</span> <br />
                        Correspondent bank account number: <span>36127476</span>
                    </p>
                </div> */}
            </div>
        </Modal >
    )
}

export default TransferModal