import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Footer from '../components/footer'
import Nav from '../components/nav'
import axios from "axios";
import Modal from 'react-modal'
import EnrollModal from '../components/enrollModal';
import TransferModal from '../components/transferModal';
import CouponModal from '../components/couponModal';
Modal.setAppElement('#root')

function Program() {
    const [bookASeat, setBookASeat] = useState(false)
    const [seatBooked, setSeatBooked] = useState(false)

    // payment modals and handles 
    const [enrollModal, setEnrollModal] = useState(false)
    const [transferModal, setTransferModal] = useState(false)
    const [couponModal, setCouponModal] = useState(false)
    const [discount, setDiscount] = useState(0)
    const [couponCode, setCouponCode] = useState("")

    const handleEnrollModal = (variable) => {
        setEnrollModal(variable)
    }

    const handleTransferModal = (variable) => {
        setTransferModal(variable)
    }

    const handleCouponModal = (variable) => {
        setCouponModal(variable)
    }

    const handleCouponCode = (e) => {
        setCouponCode(e.target.value)
    }

    // fetch coupons 
    const [coupons, setCoupons] = useState([])
    const fetchCoupons = () => {
        axios.get(`https://freshstreams.co/kickstart/api/coupon/v1/getAllCoupon`,
            { headers: { 'content-type': 'application/json' } })
            .then(response => {
                // console.log(response)
                setCoupons(response.data.data)
            }).catch(error => {
                // console.error(error)
            })
    }
    useEffect(() => {
        fetchCoupons()
    }, [])

    const [countries, setCountries] = useState([])
    const fetchCountries = async () => {
        const response = await axios
            .get(`https://restcountries.com/v3.1/all`)
            .catch((error) => [
                console.log('Err', error)
            ]);
        setCountries(response.data)
    }
    useEffect(() => {
        fetchCountries()
    }, [])

    const [fullname, setFullname] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [country, setCountry] = useState("")
    const [programme, setProgramme] = useState([])

    const changeProgram = (variable) => {
        let filter = programme.filter(value => value === variable)
        if (filter.length === 1) {
            let newProgram = programme.filter(value => value !== variable)
            setProgramme(newProgram)
        } else {
            setProgramme([...programme, variable])
        }
    }

    const [loading, setLoading] = useState(false)
    const register = () => {
        setLoading(true)
        console.log(fullname, email, phone, country, programme)
        axios.post(`https://freshstreams.co/kickstart/api/mail/v1/createmail`,
            { fullname, email, phone, country, programme },
            { headers: { 'content-type': 'application/json' } })
            .then(response => {
                console.log(response)
                setLoading(false)
                setBookASeat(false)
                setSeatBooked(true)
            }).catch(error => {
                console.error(error)
                setLoading(false)
            })
    }

    return (
        <div className="container-fluid program">
            {/* navigation */}
            <Nav />
            <div className="program-body">
                <div className="container section-1">
                    <div className="row sub-section-1 align-items-center">
                        <div className="col-lg-6 col-md-6 mb-lg-0 mb-md-0 mb-sm-5 mb-4">
                            <h1>Programs we offer</h1>
                            <p>Our unique and practical teaching sessions stated below have proved to be a useful resource
                                to Voiceover talents with a
                                reach of over 40 participants from across 4 continents globally.</p>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <img src="img/Group 102.png" alt="program" className="img-fluid" />
                        </div>
                    </div>
                    <div className="sub-section-2">
                        <div className="section">
                            <div className="number d-flex justify-content-center align-items-center">1</div>
                            <p>Workshops</p>
                        </div>
                        <div className="section">
                            <div className="number d-flex justify-content-center align-items-center">2</div>
                            <p>VO Masterclass Sessions</p>
                        </div>
                        <div className="section">
                            <div className="number d-flex justify-content-center align-items-center">3</div>
                            <p>Webinars</p>
                        </div>
                        <div className="section">
                            <div className="number d-flex justify-content-center align-items-center">4</div>
                            <p>Journey to Voiceovers<br />(3 Months Global Virtual Course)</p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid section-2 overflow-hidden">
                    <div className="row align-items-lg-center align-items-md-center">
                        <div className="col-lg-5 col-md-5 col-sm-4">
                            <img src="img/unsplash_p44B3PKChs4.png" className='img-fluid mb-lg-0 mb-md-0 mb-sm-0 mb-3' alt="" />
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-8">
                            <div className="row px-lg-0 px-md-0 px-sm-0 px-2">
                                <div className="text col-6">
                                    <h1>Workshops</h1>
                                    <p>Our workshop is focused on improving the voice actor performance and delivery in
                                        various voiceover styles and genres as
                                        well as the Voiceover Business. <br /><br />
                                        All workshops are Virtual and Hybrid in Nature.
                                    </p>
                                    <button onClick={() => setBookASeat(true)}>Book a Seat</button>
                                </div>
                                <div className="text col-6">
                                    <h1>Webinars</h1>
                                    <p>Our Webinars are interactive, enlightening and comprehensive. <br /><br />
                                        Attendance to each webinar is not limited and hold monthly.
                                    </p>
                                    <button onClick={() => setBookASeat(true)}>Book a Seat</button>
                                </div>
                                <div className="text col-6">
                                    <h1>VO MasterClass sessions</h1>
                                    <p>Our MasterClass sessions are majorly for the practicing Voiceover Talent who intends
                                        to take their craft and career many steps higher. <br /><br />
                                        Our Facilitators are Industry experts with years of experience and expertise in the
                                        Voiceover profession. <br /><br />
                                        This course is limited to a minimum of 10 participants and a maximum of 15 only.
                                    </p>
                                    <button onClick={() => setBookASeat(true)}>Book a Seat</button>
                                </div>
                                <div className="text col-6">
                                    <h1>Journey to voiceovers</h1>
                                    <p>Journey to Voiceovers is our pioneer course focused at helping the budding, aspiring
                                        as well as the practicing voice actor strengthen their Vocal abilities and learn
                                        more about the Voiceover Business on their path to Voiceover Greatness. <br /><br />
                                        The Virtual Course holds for 3 Months with facilitators from various continents of
                                        the world. <br /><br />
                                        Voiceover related topics, practical tasks, exercises and webinars are scheduled and
                                        focused on in this course. <br /><br />
                                        Journey to Voiceovers include a certificate of participation after the completion of
                                        tasks and exercises.
                                    </p>
                                    <button onClick={() => setEnrollModal(true)}>Enroll Now</button>

                                    {/* modals  */}
                                    {enrollModal && <EnrollModal
                                        enrollModal={enrollModal}
                                        handleEnrollModal={handleEnrollModal}
                                        handleTransferModal={handleTransferModal}
                                        handleCouponModal={handleCouponModal}
                                        discount={discount}
                                        couponCode={couponCode} />}
                                    {transferModal && <TransferModal
                                        transferModal={transferModal}
                                        handleEnrollModal={handleEnrollModal}
                                        handleTransferModal={handleTransferModal} />}
                                    {couponModal && <CouponModal
                                        couponModal={couponModal}
                                        coupons={coupons}
                                        handleCouponModal={handleCouponModal}
                                        handleEnrollModal={handleEnrollModal}
                                        setDiscount={setDiscount}
                                        couponCode={couponCode}
                                        handleCouponCode={handleCouponCode} />}
                                </div>

                                {/* book a seat modal  */}
                                <Modal isOpen={bookASeat} onRequestClose={() => setBookASeat(false)} id="book-a-seat">
                                    <i class="fa-solid fa-xmark" onClick={() => setBookASeat(false)}></i>
                                    <h1>Book a Seat</h1>
                                    <label htmlFor="name" >Full Name</label>
                                    <input type="text" id='name' value={fullname} onChange={(e) => setFullname(e.target.value)} placeholder='Enter fullname' />
                                    <label htmlFor="email" >Email Address</label>
                                    <input type="text" id='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter email' />
                                    <label htmlFor="number">Phone Number</label>
                                    <input type="number" id='number' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='Enter phone number' />
                                    <label htmlFor="Country">Country</label>
                                    <select name="category" id="category" onChange={(e) => setCountry(e.target.value)}>
                                        <option value="">Select country</option>
                                        {countries.sort((x, y) => {
                                            if (x.name.common < y.name.common) { return -1; }
                                            if (x.name.common > y.name.common) { return 1; }
                                            return 0;
                                        }).map((country, index) => {
                                            return <option value={country.name.common} key={index}>{country.name.common}</option>
                                        })}
                                    </select>
                                    <p>Which of the programs would you like to book for?</p>
                                    <div className="checkbox d-flex align-items-center">
                                        <input type="checkbox" id='Webinar' onChange={(e) => changeProgram('webinar')} />
                                        <label htmlFor="Webinar" className='mb-0'>Webinar</label>
                                    </div>
                                    <div className="checkbox d-flex align-items-center">
                                        <input type="checkbox" id='Workshop' onChange={(e) => changeProgram('workshop')} />
                                        <label htmlFor="Workshop" className='mb-0'>Workshop</label>
                                    </div>
                                    <div className="checkbox d-flex align-items-cente mb-lg-5r mb-md-3 mb-sm-3 mb-4">
                                        <input type="checkbox" id='master' onChange={(e) => changeProgram('masterclass')} />
                                        <label htmlFor="master" className='mb-0'>VO Masterclass Sessions</label>
                                    </div>
                                    <button onClick={register}>{loading ? <>Loading...  <i className="fa-solid fa-spinner fa-spin" /></> : "Book a Seat"}</button>
                                </Modal>

                                {/* success modal  */}
                                <Modal isOpen={seatBooked} onRequestClose={() => setSeatBooked(false)} id="seat-booked">
                                    <img src="/img/tick.png" alt="" />
                                    <h3>Seat Booked Successfully!</h3>
                                    <p className='mb-0'>Thank you for successfully booking a seat for yourself in your preferred program.</p>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container section-3">
                    <h1>Coaching</h1>
                    <p>Our in-house coaches are ready to work directly with you to help you be the best voice actor you are
                        meant to be.</p>
                    <ul>
                        <li>1 on 1 coaching</li>
                        <li>Group coaching</li>
                        <li>Corporate coaching</li>
                    </ul>
                    <p>All sessions are booked ahead and hold via Skype or Zoom.</p>
                    <Link to="/courses"><button>View Course</button></Link>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Program