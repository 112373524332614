import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Footer from '../components/footer'
import Nav from '../components/nav'
import HomeCarousel from '../components/homeCarousel'
import TestimonialCarousel from '../components/testimonialCarousel'

function Home() {
    const [video, setVideo] = useState(true)

    // history 
    const navigate = useNavigate()

    return (
        <div className="container-fluid">
            {/* navigation */}
            <Nav />
            <div className="home-carousel">
                <HomeCarousel />
            </div>
            <section className="container journey">
                <div className="alumni">
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-3 col-sm-3">
                            <img src="img/Group 71.png" className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-9">
                            <h3>Meet Our Alumni</h3>
                            <p>This is a collective, supportive and engaging community that exist as a network for
                                continuous learning, idea sharing,
                                collaborations, sharing of casting opportunities and a platform to give back to the society.
                                <br /> <br />
                                Our Global Alumni Network meet for a Virtual Hangout every month and participate in weekly
                                Voiceover reading exercises. <br /><br />
                                Membership of our Alumni community is open only to Participants of Journey to Voiceovers
                                (Our 3 Months Virtual Course).
                            </p>
                        </div>
                    </div>
                </div>
                <div className="journey">
                    <img src="img/Rectangle 31.png" alt="" />
                    <div>
                        <h1>Journey to Voiceovers</h1>
                        <p>Journey to Voiceovers is our pioneer course focused at helping the budding, aspiring as well as
                            the practicing voice
                            actor strengthen their Vocal abilities and learn more about the Voiceover Business on their path
                            to Voiceover Greatness.</p>
                        <Link to="/courses"><button>Learn more</button></Link>
                    </div>
                </div>
                <div className="testimonials container">
                    <h1>Testimonials</h1>
                    <TestimonialCarousel />
                </div>
                {/* youtube  */}
                <div className="container">
                    <div className="youtube">
                        <button id='left' onClick={() => setVideo(!video)}><i class="fa-solid fa-angle-left"></i></button>
                        <button id='right' onClick={() => setVideo(!video)}><i class="fa-solid fa-angle-right"></i></button>
                        <div className="row mb-lg-4">
                            <div className="col-lg-5">
                                <h1>Hear What Our Students Have To Say About Us</h1>
                            </div>
                            <div className="col-lg-7">
                                {video ?
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/eiZx_mc2d28" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> :
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/rD6vPIO1vyI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                }
                            </div>
                        </div>
                        <div className="nav-btn">
                            <i className={`fa-solid fa-circle ${video && 'active'}`} onClick={() => setVideo(true)}></i>
                            <i className={`fa-solid fa-circle ${!video && 'active'}`} onClick={() => setVideo(false)}></i>
                        </div>
                    </div>
                </div>
                {/* instagram  */}
                <div className="container">
                    <div className="insta">
                        <h1>Tasks and Projects of our Participants during the course</h1>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 mb-lg-5 mb-md-4 mb-sm-3 mb-3">
                                <div className="insta-card">
                                    <div className="top">
                                        <img src="/img/{1B9A9543-F8ED-4307-B316-0AB1F05264FA} 1.png" alt="img" className='cover-img' />
                                        <a href="https://www.instagram.com/p/CET7xyVF_ko/?igshid=YmMyMTA2M2Y%3D" target="_blank" className="play"><img src="/img/play.png" alt="play" /></a>
                                        <div className="profile-img">
                                            <img src="/img/{FC4CF307-7807-4573-BE6F-59C63781C866} 1.png" alt="profil-img" />
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <h3>VOICEOVER BY TOBILOBA KOLAWOLE</h3>
                                        <p>
                                            I’m grateful for the learning experience kickstartintovos, and for the beautiful friends who turned family. Special shout-out to my sistos damorealli and the boss osamagieosamede <br /><br />
                                            Script by olamayowa. It was quite challenging saying all those words in a minute but we did it. Well done sister! <br /><br />
                                            And yes, I guess you can start calling me for backstage announcing at your event, Thanks
                                        </p>
                                        <a href="https://www.instagram.com/p/CET7xyVF_ko/?igshid=YmMyMTA2M2Y%3D" target="_blank" className='mb-0'>WATCH VIDEO HERE</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 mb-lg-5 mb-md-4 mb-sm-3 mb-3">
                                <div className="insta-card">
                                    <div className="top">
                                        <img src="/img/Group 1013.png" alt="img" className='cover-img' />
                                        <a href="https://www.instagram.com/p/CET7xyVF_ko/?igshid=YmMyMTA2M2Y%3D" target="_blank" className="play"><img src="/img/play.png" alt="play" /></a>
                                        <div className="profile-img">
                                            <img src="/img/{4B95C2BB-1405-4A5C-BE51-90A253FE11A7} 1.png" alt="profil-img" />
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <h3>VOICEOVER BY ROFIATU</h3>
                                        <p>
                                            This one shake me o. They said we should write and voice over a one-minute letter to our older self. <br /><br />
                                            Sha sha, we have communed with the gods of the pen, the muses that preside over these things, and they have spoken. Last last sha, we go reach promise land. <br /><br />
                                            For now, Collet this one laidat.
                                        </p>
                                        <a href="https://www.instagram.com/p/CBX9bJVAMig/?igshid=YmMyMTA2M2Y%3D" target="_blank" className='mb-0'>WATCH VIDEO HERE</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 mb-lg-5 mb-md-4 mb-sm-3 mb-3">
                                <div className="insta-card">
                                    <div className="top">
                                        <img src="/img/{095DAFDD-398F-4CBC-9378-89047B7F46C1} 1.png" alt="img" className='cover-img' />
                                        <a href="https://www.instagram.com/tv/CUAzSCSjX-X/?igshid=YmMyMTA2M2Y%3D" target="_blank" className="play"><img src="/img/play.png" alt="play" /></a>
                                        <div className="profile-img">
                                            <img src="/img/{36ADA1EA-B6DB-4AA4-AF77-5F46ED6C4AE9} 1.png" alt="profil-img" />
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <h3>VOICEOVER BY ELVIS</h3>
                                        <p>
                                            Car commercial for kickstartintovos course <br /><br />
                                            So I’ve been wanting to try a car commercial for a while now, but not been able to wrap my head around it. <br /><br />
                                            So here we go.
                                        </p>
                                        <a href="https://www.instagram.com/tv/CUAzSCSjX-X/?igshid=YmMyMTA2M2Y%3D" target="_blank" className='mb-0'>WATCH VIDEO HERE</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 mb-lg-5 mb-md-4 mb-sm-3 mb-3">
                                <div className="insta-card">
                                    <div className="top">
                                        <img src="/img/{2AC9DDA3-3509-4AF7-9469-B7FFC30E8DA3} 1.png" alt="img" className='cover-img' />
                                        <a href="https://www.instagram.com/p/CDhTExMFHXx/?igshid=YmMyMTA2M2Y%3D" target="_blank" className="play"><img src="/img/play.png" alt="play" /></a>
                                        <div className="profile-img">
                                            <img src="/img/{4B95C2BB-1405-4A5C-BE51-90A253FE11A7} 1.png" alt="profil-img" />
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <h3>VOICEOVER BY ROFIATU</h3>
                                        <p>
                                            Guys! So, the KickstartintoVOs maiden edition is ending pretty soon! <br /><br />
                                            In welcoming the auspicious occasion, our fourth (and I think, final) task is to voice an announcement script celebrating ourselves, the graduands, in advance. <br /><br />
                                            So, here's task 4 in da bag! Tainz and goodbye.
                                        </p>
                                        <a href="https://www.instagram.com/p/CDhTExMFHXx/?igshid=YmMyMTA2M2Y%3D" target="_blank" className='mb-0'>WATCH VIDEO HERE</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 mb-lg-5 mb-md-4 mb-sm-3 mb-3">
                                <div className="insta-card">
                                    <div className="top">
                                        <img src="/img/{87A53FEE-EBBE-49B1-A8C2-FA319C72A49B} 1.png" alt="img" className='cover-img' />
                                        <a href="https://www.instagram.com/p/CBfjfjVFE8Z/?igshid=YmMyMTA2M2Y%3D" target="_blank" className="play"><img src="/img/play.png" alt="play" /></a>
                                        <div className="profile-img">
                                            <img src="/img/{FC4CF307-7807-4573-BE6F-59C63781C866} 1.png" alt="profil-img" />
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <h3>VOICEOVER BY TOBILOBA KOLAWOLE</h3>
                                        <p>
                                            At kickstartintovos, we were asked to voice a letter to our older self, there’s only little one can say in a minute and yes, I still expect myself to be a babe even with my grey hair. <br /><br />
                                            If you were to write to your old self what would you say? <br /><br />
                                            Meanwhile, the struggle for pidgin though.
                                        </p>
                                        <a href="https://www.instagram.com/p/CBfjfjVFE8Z/?igshid=YmMyMTA2M2Y%3D" target="_blank" className='mb-0'>WATCH VIDEO HERE</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 mb-lg-5 mb-md-4 mb-sm-3 mb-3">
                                <div className="insta-card">
                                    <div className="top">
                                        <img src="/img/{443CA02E-7124-4B97-AA31-F736D826EA9A} 1.png" alt="img" className='cover-img' />
                                        <a href="https://www.instagram.com/p/CTtpVl4ldah/?igshid=YmMyMTA2M2Y%3D" target="_blank" className="play"><img src="/img/play.png" alt="play" /></a>
                                        <div className="profile-img">
                                            <img src="/img/{567F3A3D-BEBD-45FD-945E-DAE5E1058164} 1.png" alt="profil-img" />
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <h3>VOICEOVER BY TANIA</h3>
                                        <p>
                                            So this is my Task 1 submission for kickstartintovos journeytovoiceovers course, featuring Ocean Views Destination Edition soy candles from @flaming_flamingo_australia 🏝 <br /><br />
                                            Considering it's recorded on my phone, raw and unedited, I think it turned out alright <br /><br />
                                            I tried to edit in some relaxing beach sounds in the background. It did not go well 😂
                                        </p>
                                        <a href="https://www.instagram.com/p/CTtpVl4ldah/?igshid=YmMyMTA2M2Y%3D" target="_blank" className='mb-0'>WATCH VIDEO HERE</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 mb-lg-5 mb-md-4 mb-sm-3 mb-3">
                                <div className="insta-card">
                                    <div className="top">
                                        <img src="/img/{D5AFC64A-8F3F-45EB-A1F9-8CF8542FA6BF} 1.png" alt="img" className='cover-img' />
                                        <a href="https://www.instagram.com/p/CCjQOEfFSqs/?igshid=YmMyMTA2M2Y%3D" target="_blank" className="play"><img src="/img/play.png" alt="play" /></a>
                                        <div className="profile-img">
                                            <img src="/img/{FC4CF307-7807-4573-BE6F-59C63781C866} 1.png" alt="profil-img" />
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <h3>VOICEOVER BY TOBILOBA KOLAWOLE</h3>
                                        <p>
                                            Aunty rona has made a lot of things change. I can’t remember the last time I attended a wedding party. <br /><br />
                                            The one that amazes me is how the markets in Abuja are constantly full on the “new market days”, especially the food section! <br /><br />
                                            What has changed in your area? Do you miss wedding parties as well?
                                        </p>
                                        <a href="https://www.instagram.com/p/CCjQOEfFSqs/?igshid=YmMyMTA2M2Y%3D" target="_blank" className='mb-0'>WATCH VIDEO HERE</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 mb-lg-5 mb-md-4 mb-sm-3 mb-3">
                                <div className="insta-card">
                                    <div className="top">
                                        <img src="/img/{7888F32C-38EF-4576-82DA-E5583CF8C55E} 1.png" alt="img" className='cover-img' />
                                        <a href="https://www.instagram.com/tv/Ca1QTzej8Vo/?igshid=YmMyMTA2M2Y%3D" target="_blank" className="play"><img src="/img/play.png" alt="play" /></a>
                                        <div className="profile-img">
                                            <img src="/img/{9164248E-5C30-4C8F-9CBD-0015AB426B49} 1.png" alt="profil-img" />
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <h3>VOICEOVER BY EDWARD STOKES</h3>
                                        <p>
                                            Yo Everyone I'm back again (didn't take longer than a month this time) to show ya something I made during my 3 Month VO course with kickstartintovos. <br /><br />
                                            This was our individual task 1 in which we were told to write a script of 150 words for a jingle or promo for either radio, TV or online purposes that would sell a product or service under 1 minute (I went over).
                                        </p>
                                        <a href="https://www.instagram.com/tv/Ca1QTzej8Vo/?igshid=YmMyMTA2M2Y%3D" target="_blank" className='mb-0'>WATCH VIDEO HERE</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 mb-lg-5 mb-md-4 mb-sm-3 mb-3">
                                <div className="insta-card">
                                    <div className="top">
                                        <img src="/img/Group 1014.png" alt="img" className='cover-img' />
                                        <a href="https://www.instagram.com/tv/CA1Br7PFz6x/?igshid=YmMyMTA2M2Y%3D" target="_blank" className="play"><img src="/img/play.png" alt="play" /></a>
                                        <div className="profile-img">
                                            <img src="/img/{FC4CF307-7807-4573-BE6F-59C63781C866} 1.png" alt="profil-img" />
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <h3>VOICEOVER BY TOBILOBA KOLAWOLE</h3>
                                        <p>
                                            My voice ❤️ <br /><br />
                                            Where are my Yoruba sisters? 😎 <br /><br />
                                            So I decided to challenge myself and do more with my voice 🤞🏾
                                        </p>
                                        <a href="https://www.instagram.com/tv/CA1Br7PFz6x/?igshid=YmMyMTA2M2Y%3D" target="_blank" className='mb-0'>WATCH VIDEO HERE</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* learn  */}
                <div className="container">
                    <div className="learn">
                        <h1>LEARN FROM THE VERY BEST</h1>
                        <p>Join our course and start building the most wanted career available today. We make sure every class is easily understood, and that all students reach the same level of expertise needed for today’s voiceover industry. </p>
                        <button onClick={() => navigate("/programs")}>Learn more</button>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Home 