import React, { useState } from 'react'
import Modal from 'react-modal'
Modal.setAppElement('#root')

function BlessingKure() {
    const [modal, setModal] = useState(false)
    return (
        <div className="card">
            <div className="top">
                <img src="/img/blessing.jpg" className="img-fluid" alt="Stephaine Ugbeye" />
            </div>
            <div className="bottom">
                <h4>Blessyn Kure</h4>
                <h5 onClick={() => setModal(true)}>Click to read more</h5>
            </div>
            <Modal isOpen={modal} onRequestClose={() => setModal(false)} className="facilitator-modal">
                <i class="fa-solid fa-xmark" onClick={() => setModal(false)}></i>
                <div className="row align-items-center mb-5">
                    <div className="col-5">
                        <img src="/img/blessing.jpg" className='img-fluid' alt="Stephaine Ugbeye" />
                    </div>
                    <div className="col-7">
                        <h1 className='mb-0'>Blessyn Kure</h1>
                    </div>
                </div>
                <h3>Background</h3>
                <p className='mb-0'>
                    Blessyn Kure is a tri-lingual spoken word and voice over artist, multi-media personality, social advocate, travel vlogger and event host. <br />
                    With experience in voice acting spanning almost a decade, she has enjoyed the opportunity of not just bringing words to life, but also delivering professionally finished voicing that meets client needs. <br /><br />
                    Blessyn has a clientele base of internationally renowned brands as well as local organisations. She isn’t just blessed with a great voice, but also versatility which allows her deliver voiceovers in a variety of English accents, Pidgin English, and Hausa languages. <br />
                    She offers a wide range of voicing services like Audiobooks, Commercials, Corporate Video, Documentaries, Dubbing, E-Learning, Live announcing on camera, Movie trailers, Online promotions/commercials, Phone IVR systems, Podcasts, Showreel, TV or radio Commercials, TV Shows and movies, Video and computer games and much more. <br /><br />
                    She also lends her voice to advocate for topical societal issues within her locality and the world at large building sustainable and meaningful engagement for the development of youth in general and women in particular while leveraging on travel vlogging to showcase the inherent beauty in diversity.
                </p>
            </Modal>
        </div>
    )
}

export default BlessingKure