import React, { useState } from 'react'
import Modal from 'react-modal'
Modal.setAppElement('#root')

function MarsLipowski() {
    const [modal, setModal] = useState(false)
    return (
        <div className="card">
            <div className="top">
                <img src="/img/h6.png" className="img-fluid" alt="Mars Lipowski" />
            </div>
            <div className="bottom">
                <h4>Mars Lipowski</h4>
                <h5 onClick={() => setModal(true)}>Click to read more</h5>
            </div>
            <Modal isOpen={modal} onRequestClose={() => setModal(false)} className="facilitator-modal">
                <i class="fa-solid fa-xmark" onClick={() => setModal(false)}></i>
                <div className="row align-items-center mb-5">
                    <div className="col-5">
                        <img src="/img/h6.png" className='img-fluid' alt="Mars Lipowski" />
                    </div>
                    <div className="col-7">
                        <h1 className='mb-0'>Mars Lipowski</h1>
                    </div>
                </div>
                <h3>Background</h3>
                <p className='mb-0'>
                    Mars Lipowski is a professional, bilingual voiceover artist and corporate narrator. Through a combination of courses and mentoring he's been trained in all aspects of the voiceover business to a professional level. The skills taught, include advanced techniques such as delivery speed, inflection and prosody patterns, and gaming techniques such as character creation, non-scripted vocals, and loop grouping. <br /><br />
                    In addition, Mars is very proficient with complimentary skills such as self direction, video production, and audio engineering skills (advanced editing and mastering). <br /><br />
                    Due to the quality of his voice and accent, he is frequently asked to voice gravely, evil or commanding video game characters, or productions where authoritative native Polish language or international English accent is needed.
                </p>
            </Modal>
        </div>
    )
}

export default MarsLipowski