import React, { useState } from 'react'
import axios from "axios";
import Modal from 'react-modal'
Modal.setAppElement('#root')

function CouponModal({ couponModal, handleCouponModal, handleEnrollModal, setDiscount, couponCode, handleCouponCode, coupons }) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    const checkCoupon = () => {
        setLoading(true)
        setError(null)
        const couponFilter = coupons.filter(coupon => coupon.couponCode === couponCode)
        if (couponFilter.length === 0) {
            setError("Invalid Coupon, please enter a valid Coupon code")
            setLoading(false)
        } else {
            setDiscount(couponFilter[0].couponDiscount)
            handleCouponModal(false)
            handleEnrollModal(true)
            setLoading(false)

        }


        // console.log(couponCode)
        // axios.get(`https://freshstreams.co/kickstart/api/coupon/v1/fetchCoupon`,
        //     { couponCode },
        //     { headers: { 'content-type': 'application/json' } })
        //     .then(response => {
        //         console.log(response)
        //         setLoading(false)
        //     }).catch(error => {
        //         console.error(error)
        //         setLoading(false)
        //     })

        // axios.get(`https://freshstreams.co/kickstart/api/coupon/v1/fetchCoupon`, { couponCode })
        //     .then(response => {
        //         console.log(response)
        //         // setDiscount(20)
        //         // handleCouponModal(false)
        //         // handleEnrollModal(true)
        //     }).catch(error => {
        //         console.log(error)
        //         setError("Invalid Coupon, please enter a valid Coupon code")
        //         setLoading(false)
        //     })
    }

    return (
        <Modal isOpen={couponModal} onRequestClose={() => handleCouponModal(false)} id="coupon-modal">
            <i class="fa-solid fa-xmark" onClick={() => handleCouponModal(false)}></i>
            <h1>Discount Coupon</h1>
            <label htmlFor="code">Enter Coupon Code </label>
            <input type="text" id='code' placeholder='Enter coupon here' value={couponCode} onChange={(e) => handleCouponCode(e)} />
            <p>{error}</p>
            <button onClick={checkCoupon}>{loading ? <>Loading...  <i className="fa-solid fa-spinner fa-spin" /></> : "Apply"}</button>
        </Modal>
    )
}

export default CouponModal