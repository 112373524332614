import React from 'react'
import { PaystackButton } from 'react-paystack';
import axios from "axios";
import Modal from 'react-modal'
Modal.setAppElement('#root')

function EnrollModal({ enrollModal, handleEnrollModal, handleTransferModal, handleCouponModal, discount }) {

    // paystack config 
    const config = {
        reference: (new Date()).getTime().toString(),
        email: "kickstartintovos@gmail.com",
        amount: (155000 - (discount / 100) * 155000) * 100,
        publicKey: 'pk_live_29d09c3fc334ce65eaa67aa3b27e49f1b37e105f',
        // publicKey: 'pk_test_69dc62524e5dbbb392d9a9c972fa96c7fb382378',
    };

    const handlePaystackSuccessAction = (reference) => {
        paystackFinishPayment(reference);
    };

    const handlePaystackCloseAction = (reference) => {
    }

    // paystack payment button 
    const paystackFinishPayment = async (reference) => {
        if (discount === 0) {
            const response = await axios
                .post(`https://freshstreams.co/kickstart/api/tranactions/v1/create`, { reference: reference })
                .catch((error) => {
                    console.error(error)
                })
            handleEnrollModal(false)
            console.log(response)
        } else {
            const response = await axios
                .post(`https://freshstreams.co/kickstart/api/tranactions/v1/create`, { reference: reference, coupon: "kickstart" })
                .catch((error) => {
                    console.error(error)
                })
            handleEnrollModal(false)
            console.log(response)
        }
    }

    const componentProps = {
        ...config,
        text: 'Proceed with Paystack',
        onSuccess: (reference) => handlePaystackSuccessAction(reference),
        onClose: (reference) => handlePaystackCloseAction(reference),
    };

    return (
        <Modal isOpen={enrollModal} onRequestClose={() => handleEnrollModal(false)} id="enroll-modal" >
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-6">
                    <img src="/img/enroll.png" className='img-fluid' alt="enroll" />
                </div>
                <div className="col-lg-7 col-md-7 col-sm-6 text">
                    <i class="fa-solid fa-xmark" onClick={() => handleEnrollModal(false)}></i>
                    <h2>Become a Voice Actor</h2>
                    <p>Enroll for our 3-months virtual course open for all Voiceover talents at all levels.</p>
                    {discount === 0 ?
                        <>
                            <h3>TRAINING FEE</h3>
                            <h1>$250 / N155,000</h1>
                        </> :
                        <div className="row">
                            <div className="col-6">
                                <h3>TRAINING FEE</h3>
                                <s><h1>$250 / N155,000</h1></s>
                            </div>
                            <div className="col-6">
                                <h3>DISCOUNT FEE</h3>
                                <h1>${250 - (discount / 100) * 250} / N{155000 - (discount / 100) * 155000}</h1>
                            </div>
                        </div>
                    }
                    <PaystackButton {...componentProps} className='paystack' />
                    <button id='transfer' onClick={() => {
                        handleEnrollModal(false);
                        handleTransferModal(true)
                    }}>Pay with Bank Transfer</button>
                    <h4 className='mb-0' onClick={() => {
                        handleEnrollModal(false)
                        handleCouponModal(true)
                    }} >Have a discount coupon? Click here</h4>
                </div>
            </div>
        </Modal >
    )
}

export default EnrollModal