import React, { Component } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class TestimonialCarousel extends Component {
    state = {
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            900: {
                items: 1,
            },
            1000: {
                items: 1,
            },
            1100: {
                items: 1,
            },
            1200: {
                items: 1,
            },
            1300: {
                items: 1,
            },
            1400: {
                items: 1,
            },
        },
    }
    render() {
        return (
            <OwlCarousel
                className='owl-theme'
                loop margin={1000}
                nav //arrows right and left
                mouseDrag
                touchDrag
                pullDrag
                freeDrag
                // slideSpeed={1000}
                // paginationSpeed={400}
                // animateIn="fadeIn" // fade in
                // animateOut="fadeOut" // fade out
                dots={true} // dots in the bottom
                autoplay
                autoplayTimeout="10000" // time in milliseconds before moving to the next slide
                responsive={this.state.responsive} // resposnive class sizes 
            >
                <div class='item'>
                    <h3>Priscilla</h3>
                    <p className="role">(Participant, Q&amp; A sessions with Kelly Hope Taylor &amp; Donald Gadson)</p>
                    <img src="img/comment.png" alt="" />
                    <p>About today! It was an amazing session! I learnt a lot😀, Okay let me share some lessons , It's
                        important to build
                        relationships, It's important to have a coach, it's important to do your best whether you're being
                        paid for it or not,
                        give it your best! <br />
                        Meet the wonderful guests Kelly Hope Taylor and Donald Gadson. Thank you Kickstart Into VOs for
                        this!</p>
                </div>
                <div class='item'>
                    <h3>Enitan Deborah Olamide</h3>
                    <p className="role">Participant, Journey to Voiceovers/ Voice Acting</p>
                    <img src="img/comment.png" alt="" />
                    <p>
                        I use to think how hard can it be to just voice scripts, is it not to just say the words and add emotions to it. But then Kickstart into VOs taught me that there's more to the script, there's getting the right voice, right emotions, knowing your clients, targeted audience, meeting deadlines and at the same time making money.It was three month of learning,unlearning and relearning and I had fun all the way. <br /><br />
                        Yes it's not easy there was networking disturbance, there are times I forget about the class and they had to call (thank you so much for doing that), but in all I'm grateful to the facilitators,the guest facilitators for their patience, time and resources. I pray the good Lord will replenish and take you all to greater places. So it's a new me to voice acting and I'm sure with the skills and knowledge acquired I'll do you proud and I'll also keep learning.
                    </p>
                </div>
                <div class='item'>
                    <h3>Tobiloba Kolawole</h3>
                    <p className="role"></p>
                    <img src="img/comment.png" alt="" />
                    <p>I would like to appreciate the entire Kickstart Into VOs team for their efforts in making us learn so much in this time.  I have been able to understand how to read and interpret different genres of scripts and also voice them appropriately. This class has also improved my confidence in the gift that God has given me and exposed me to the industry. <br /><br />
                        I hope the lessons I have learned will be evident in my future productions and do the academy proud! Thanks again. God bless!</p>
                </div>
                <div class='item'>
                    <h3>Rita Ekeagu</h3>
                    <p className="role">Kickstart Into VOS 3 Months Online Course Participant</p>
                    <img src="img/comment.png" alt="" />
                    <p>
                        My 3 months training on VO with kickstart into vos has been amazing. I was able to learn how to use my voice well, know my range and how best to use it. Also,the training has shaped me in being confidence with myself and channeled me to the right genre that best suits my voice and business wise. <br /><br />
                        Kickstart into vos is a place to be, if you really want to kickstart your journey as a voiceover artist. They have got great facilitators who are passionate about what they do by sharing their knowledge to students willing to learn. Thank you
                    </p>
                </div>
                <div class='item'>
                    <h3>Taiwo Ogunnaike</h3>
                    <p className="role"></p>
                    <img src="img/comment.png" alt="" />
                    <p>
                        Kelly and Donald are such amazing people. It's like they were both intentional about pouring all they knew into us, they are both so positive, funny and full of life. It was such an impactful moment. <br /><br />
                        I did learn that in as much as it is important to have coaches and mentors in the voiceover field, you can be authentic and original as well without necessarily imitating anyone. You also have to constantly give value. And never forget to take care of your health. Plus a couple of other things you would have learnt if you had been there😉
                    </p>
                </div>
                <div class='item'>
                    <h3>Taiwo Ogunaike </h3>
                    <p className="role">Participant, Journey to Voiceovers/ Voice Acting</p>
                    <img src="img/comment.png" alt="" />
                    <p>
                        It is often said that life makes more sense when one finds purpose and when such purpose aligns with one's talents and persona. Few months ago, I was just a girl with a voice. Now, I'm still a girl with a voice, but guess what, I'm also a girl with passion, drive, talents. I discovered Kickstartintovos and I have learned how to. Three months of being a student of Kickstartintovos, I didn't just learn how to succeed as a voice artiste, I learned the art and act of success. I've learnt that it's never just about the talent or skills, it's also about the value I learnt about leveraging my skills. To get the best result, I have learnt to be confident in my voice and in my abilities, to never give up on getting better, always improve and how to remain invaluable. The facilitators have all been amazing and consistent. Also deliberate about delivering and impacting us with the right knowledge and mentality. I've learnt to be original in spite of it. Kickstartintovos has taught me strategies and tips on how to generally remain valuable as a voice artiste. Overall, the coach, Mr. Osas has been encouraging and supportive. I also now have a wonderful family and community where we can help ourselves get better.  Kickstartintovos really gave me more than I signed up for. And for this, I'll be eternally grateful
                    </p>
                </div>
            </OwlCarousel>
        )
    }
}

export default TestimonialCarousel