import React, { useState } from 'react'
import Modal from 'react-modal'
Modal.setAppElement('#root')

function HussainAmiri() {
    const [modal, setModal] = useState(false)
    return (
        <div className="card">
            <div className="top">
                <img src="/img/h 1.png" className="img-fluid" alt="Hussain Amiri" />
            </div>
            <div className="bottom">
                <h4>Hussain Amiri</h4>
                <h5 onClick={() => setModal(true)}>Click to read more</h5>
            </div>
            <Modal isOpen={modal} onRequestClose={() => setModal(false)} className="facilitator-modal">
                <i class="fa-solid fa-xmark" onClick={() => setModal(false)}></i>
                <div className="row align-items-center mb-5">
                    <div className="col-5">
                        <img src="/img/h 1.png" className='img-fluid' alt="Hussain Amiri" />
                    </div>
                    <div className="col-7">
                        <h1 className='mb-0'>Hussain Amiri</h1>
                    </div>
                </div>
                <h3>Background</h3>
                <p className='mb-0'>
                    Hussain Amiri is a Voiceover Artist and believes in the power of ones voice far beyond the acts of speaking and listening, 'our voice is our legacy' <br /><br />
                    He created a brand called PYLO which stands for Put Your Lights On. PYLO is an avenue for him to always pursue his passion of being behind a mic, creating content and writing with the intent to support and contribute in tackling all angles of awareness with the sole purpose of bettering ourselves & our societies.
                </p>
            </Modal>
        </div>
    )
}

export default HussainAmiri