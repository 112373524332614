import React, { useState, useEffect } from 'react'
import Nav from '../components/nav'
import Footer from '../components/footer'
import axios from "axios";
import Question1 from '../components/questions/question1'
import Question2 from '../components/questions/question2'
import Question3 from '../components/questions/question3'
import Question4 from '../components/questions/qustion4'
import Question5 from '../components/questions/question5'
import Question6 from '../components/questions/question6'
import EnrollModal from '../components/enrollModal';
import TransferModal from '../components/transferModal';
import CouponModal from '../components/couponModal';

function Courses() {
    // payment modals and handles 
    const [enrollModal, setEnrollModal] = useState(false)
    const [transferModal, setTransferModal] = useState(false)
    const [couponModal, setCouponModal] = useState(false)
    const [discount, setDiscount] = useState(0)
    const [couponCode, setCouponCode] = useState("")

    const handleEnrollModal = (variable) => {
        setEnrollModal(variable)
    }

    const handleTransferModal = (variable) => {
        setTransferModal(variable)
    }

    const handleCouponModal = (variable) => {
        setCouponModal(variable)
    }

    const handleCouponCode = (e) => {
        setCouponCode(e.target.value)
    }

    // fetch coupons 
    const [coupons, setCoupons] = useState([])
    const fetchCoupons = () => {
        axios.get(`https://freshstreams.co/kickstart/api/coupon/v1/getAllCoupon`,
            { headers: { 'content-type': 'application/json' } })
            .then(response => {
                // console.log(response)
                setCoupons(response.data.data)
            }).catch(error => {
                // console.error(error)
            })
    }
    useEffect(() => {
        fetchCoupons()
    }, [])

    return (
        <div className="container-fluid courses">
            {/* navigation */}
            <Nav />
            <div className="container">
                <div className="row section-1 align-items-center">
                    <div className="col-lg-6 col-md-6 mb-lg-0 mb-md-0 mb-sm-5 mb-5">
                        <h1>Journey to Voiceovers</h1>
                        <p>Journey to Voiceovers is our pioneer course focused at helping the budding, aspiring as well
                            as the practicing voice
                            actor strengthen their Vocal abilities and learn more about the Voiceover Business on their
                            path to Voiceover Greatness.</p>
                        <button onClick={() => setEnrollModal(true)}>Enroll Now</button>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <img src="img/Group 105.png" alt="course" className="img-fluid" />
                    </div>
                </div>
                <div className="section-2">
                    <h1>Change your life, learn voice acting, learn the voiceover business</h1>
                    <p>The Virtual Course holds for 3 Months with facilitators from various continents of the world.
                        Practical tasks,
                        exercises and webinars are scheduled and focused on in this course. Journey to Voiceovers include a
                        certificate of
                        participation after the completion of tasks and exercises.
                    </p>
                </div>
                <div className="section-3">
                    <h1>What you’ll learn</h1>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 1</p>
                            <h6>Representation as a Voiceover Talent</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 2</p>
                            <h6>Vocal Care and Vocal Health</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 3</p>
                            <h6>Vocal Performance and Vocal Delivery</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 4</p>
                            <h6>Casting and Auditions</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 5</p>
                            <h6>Voiceover Terminologies and Standard Industry Practices</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 6</p>
                            <h6>Turning your Passion to Profit</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 7</p>
                            <h6>Setting and Planning Business Goals as a Voiceover Talent.</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 8</p>
                            <h6>Branding and Marketing</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 9</p>
                            <h6>The business basics a Voice Actor needs</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 10</p>
                            <h6>Developing Characters for Animation</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 11</p>
                            <h6>The Strength and Power in your Voice</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 12</p>
                            <h6>Interpreting Scripts and Character Analysis</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 13</p>
                            <h6>Overcoming Fear and Doubts as a Voice Actor</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 14</p>
                            <h6>Developing a high performance and growth mindset as a Voice Actor</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 15</p>
                            <h6>Voicing in Documentaries, Biographies and Memoirs</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 16</p>
                            <h6>Rates and Negotiation</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <p>UNIT 17</p>
                            <h6>Preparing and making your Voiceover Demo/Reel</h6>
                        </div>
                    </div>
                </div>
                <div className="section-4">
                    <div className="row align-items-center">
                        <div className="col-7 mb-lg-0 mb-md-0 mb-sm-0 mb-4">
                            <h1>Get Trained. Get Skilled. Become a Voice Actor.</h1>
                            <p>Learn from the best. <br />Class starts 13th of October, 2022.</p>
                            <button onClick={() => setEnrollModal(true)}>Click to Enroll Now</button>
                        </div>
                        <div className="col-5 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-start">
                            <div className="cover">
                                <h3>Training Fee</h3>
                                <h2>$250/N155,000</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-5">
                    <h1>Frequently Asked Questions</h1>
                    <Question1 />
                    <Question2 />
                    <Question3 />
                    <Question4 />
                    <Question5 />
                    <Question6 />
                </div>
                <div className="section-6">
                    <h1>Are you ready to become a Voice Actor?</h1>
                    <button onClick={() => setEnrollModal(true)}>Click to Enroll Now</button>
                </div>

                {/* modals  */}
                {enrollModal && <EnrollModal
                    enrollModal={enrollModal}
                    handleEnrollModal={handleEnrollModal}
                    handleTransferModal={handleTransferModal}
                    handleCouponModal={handleCouponModal}
                    discount={discount}
                    couponCode={couponCode} />}
                {transferModal && <TransferModal
                    transferModal={transferModal}
                    handleEnrollModal={handleEnrollModal}
                    handleTransferModal={handleTransferModal} />}
                {couponModal && <CouponModal
                    couponModal={couponModal}
                    coupons={coupons}
                    handleCouponModal={handleCouponModal}
                    handleEnrollModal={handleEnrollModal}
                    setDiscount={setDiscount}
                    couponCode={couponCode}
                    handleCouponCode={handleCouponCode} />}
            </div>
            <Footer />
        </div>
    )
}

export default Courses