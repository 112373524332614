import React, { Component } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class HomeCarousel extends Component {
    state = {
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            900: {
                items: 1,
            },
            1000: {
                items: 1,
            },
            1100: {
                items: 1,
            },
            1200: {
                items: 1,
            },
            1300: {
                items: 1,
            },
            1400: {
                items: 1,
            },
        },
    }
    render() {
        return (
            <OwlCarousel
                className='owl-theme'
                loop margin={1000}
                // nav //arrows right and left
                mouseDrag
                touchDrag
                pullDrag
                freeDrag
                slideSpeed={1000}
                paginationSpeed={400}
                animateIn="fadeIn" // fade in
                animateOut="fadeOut" // fade out
                dots={true} // dots in the bottom
                autoplay
                autoplayTimeout="5000" // time in milliseconds before moving to the next slide
                responsive={this.state.responsive} // resposnive class sizes 
            >
                <div class='item'>
                    <header id="header1">
                        <h1>Your <span>Voiceover</span> Journey Starts <span>Here!</span></h1>
                        <p>Start creating professional voice overs and be a voice actor!</p>
                    </header>
                </div>
                <div class='item'>
                    <header id="header2">
                        <h1>Your <span>Voice</span> is your Identity, <span>Own it!</span></h1>
                        <p>Go global, Your voice matters.</p>
                    </header>
                </div>
                <div class='item'>
                    <header id="header3">
                        <h1>Learn <span>Beyond</span> Boundaries</h1>
                        <p>Get trained and coached by our elite team of global facilitators</p>
                    </header>
                </div>
                <div class='item'>
                    <header id="header4">
                        <h1>Join Our Elite <span>Voiceover</span> Community</h1>
                        <p>Know the A-Z of voice acting</p>
                    </header>
                </div>
            </OwlCarousel>
        )
    }
}

export default HomeCarousel