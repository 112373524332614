import React, { useState } from 'react'
import Modal from 'react-modal'
Modal.setAppElement('#root')

function KhumiHamese() {
    const [modal, setModal] = useState(false)
    return (
        <div className="card">
            <div className="top">
                <img src="/img/h5.png" className="img-fluid" alt="Khumi Hamese" />
            </div>
            <div className="bottom">
                <h4>Khumi Hamese</h4>
                <h5 onClick={() => setModal(true)}>Click to read more</h5>
            </div>
            <Modal isOpen={modal} onRequestClose={() => setModal(false)} className="facilitator-modal">
                <i class="fa-solid fa-xmark" onClick={() => setModal(false)}></i>
                <div className="row align-items-center mb-5">
                    <div className="col-5">
                        <img src="/img/h5.png" className='img-fluid' alt="Khumi Hamese" />
                    </div>
                    <div className="col-7">
                        <h1 className='mb-0'>Khumi Hamese</h1>
                    </div>
                </div>
                <h3>Background</h3>
                <p className='mb-0'>
                    Khumi Hamese is a vibrant young lady with a love for broadcasting. <br /><br />
                    She began her journey in radio, which led her to doing voice over work. Khumi started doing voices in 2009 at the campus radio station where she worked. In 2010, She got signed to Owen S Management and began doing voices on a much larger scale. <br /><br />
                    A few months after signing with them, Khumi voiced  TV adverts for Danon Yo-Jelly campaign and did 3 adverts for them thereafter. After 8 years of doing different radio and TV adverts, She secured her first international voice over gig. <br /><br />
                    On December 2nd, 2018 she was live from FNB Stadium as the Voice Of God for the Global Citizen Mandela 100 concert in Johannesburg. That was just one of the many highlights of her career. Behind the mic, she gets to not only do what she loves. She also get to be a different person each time.
                </p>
            </Modal>
        </div>
    )
}

export default KhumiHamese