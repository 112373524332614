import React, { useState } from 'react'
import Modal from 'react-modal'
Modal.setAppElement('#root')

function EmilyCass() {
    const [modal, setModal] = useState(false)
    return (
        <div className="card">
            <div className="top">
                <img src="/img/h3.png" className="img-fluid" alt="Emily Cass" />
            </div>
            <div className="bottom">
                <h4>Emily Cass</h4>
                <h5 onClick={() => setModal(true)}>Click to read more</h5>
            </div>
            <Modal isOpen={modal} onRequestClose={() => setModal(false)} className="facilitator-modal">
                <i class="fa-solid fa-xmark" onClick={() => setModal(false)}></i>
                <div className="row align-items-center mb-5">
                    <div className="col-5">
                        <img src="/img/h3.png" className='img-fluid' alt="Emily Cass" />
                    </div>
                    <div className="col-7">
                        <h1 className='mb-0'>Emily Cass</h1>
                    </div>
                </div>
                <h3>Background</h3>
                <p className='mb-0'>
                    Emily Cass  is a multi-award nominated Voice Actor/Actor based in London. <br /><br />
                    She has voiced for clients such as BBC, Virgin, Spotify, New Look, Kinder Chocolate and more. <br /><br />
                    Her services include ADR/Lip-Sync, Animation, Announcements, <br />
                    Audio Books, Commercials (Apps, Internet, TV and Radio), , In-Store Announcements, Telephone and IVR , Trailer Voices.
                </p>
            </Modal>
        </div>
    )
}

export default EmilyCass